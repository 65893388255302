.c-flex {
  position: absolute;
  margin: 7.5vw;
  width: 85vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.head {
  width: 90vw;
  height: 5vw;
  margin: 0;
  padding: 0;
  margin-bottom: 5vw;
}

div.tool {
  border-bottom: 2px solid rgb(0, 0, 0);
  margin-bottom: 5vw;
  /* background-color: aliceblue; */
}

div.tool-head {
  width: 85vw;
  height: calc(10vw - 1.5px);
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
}

img.tool-head {
  width: 5vw;
  height: 5vw;
}

.clip-path {
  clip-path: circle(2.5vw at center);
}

div.tool-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-1 {
  width: 30vw;
  height: 15vw;
  background-color: white;
  box-shadow: 0px 0px 18px rgb(207, 207, 207);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vw;
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vw;
}
.c-1-head {
  width: 90%;
  padding: 5%;
}

.c-1-drop {
  margin: 20px 0px;
  width: 90%;
}

div.viz1 {
  width: 35vw;
  height: 35vw;
  margin: 5vw;
  display: flex;
  background-color: white;
}

div.viz1 > svg {
  width: 91%;
  height: 100%;
}

svg.scatter {
}

svg.histogram > text {
  z-index: 4;
  font-size: 3vw;
  fill: rgb(90, 90, 90);
  font-family: 'Tungsten A', 'Tungsten B';
  text-transform: uppercase;
  font-weight: 600;
}

div.yaxis {
  height: 100%;
  width: 9%;
  font-size: 3vw;
  color: rgb(90, 90, 90);
  font-family: 'Tungsten A', 'Tungsten B';
  text-transform: uppercase;
  font-weight: 600;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
}
