header {
  pointer-events: none;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  height: 45px;
  position: fixed;
  z-index: 4;
  top: 0px;
}

header svg {
  padding: 4px;
  pointer-events: all;
}

/* footer {
  pointer-events: none;
  width: 100vw;
  display: flex;
  height: 400px;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
} */

main {
  position: absolute;
}

@media only screen and (max-width: 500px) {
  header {
    height: 55px;
    top: calc(100vh - 55px);
    backdrop-filter: blur(5px);
  }
}
