@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300&family=Roboto+Mono:wght@200;300;400;500&display=swap');

main {
  width: 100vw;
  position: relative;
}

div.home {
  width: 100vw;
}

div.home > svg {
  position: absolute;
}

svg.main {
  top: 0;
  left: 0;
  z-index: 2;
}

svg.graph {
  top: 30vw;
  left: 10vw;
}

img.logo {
  width: 30vw;
}

div.grid {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 90vw;
  margin: 5vw;
  flex-wrap: wrap;
  z-index: -1;
}

div.grid > div {
  width: 5vw;
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
}

div.grid > div > div {
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background-color: black;
}

img.gradient0 {
  height: 15.1vw;
  left: 7.5vw;
  top: 32.45vw;
  z-index: 3;
  position: absolute;
  filter: grayscale(100%) brightness(150%);
}

div.gradient {
  height: 5.03vw;
  left: 7.5vw;
  z-index: 2;
  position: absolute;
}

div.gradient1 {
  top: 32.5vw;
  width: 20vw;
  background: linear-gradient(
    to right,
    rgb(255, 196, 133),
    rgba(255, 208, 168, 0.706)
  );
  /* url(https://grainy-gradients.vercel.app/noise.svg); */
}
div.gradient2 {
  top: 37.5vw;
  width: 25vw;
  background: linear-gradient(to right, rgb(255, 158, 114), rgb(255, 199, 171));
  /* url(https://grainy-gradients.vercel.app/noise.svg); */
}
div.gradient3 {
  top: 42.5vw;
  width: 30vw;
  background: linear-gradient(to right, rgb(255, 114, 95), rgb(255, 163, 153));
  /* url(https://grainy-gradients.vercel.app/noise.svg); */
}

h1 {
  font-size: 6.8vw;
  letter-spacing: 0.08vw;
  color: black;
  font-family: 'Dixon';
  text-transform: uppercase;
  line-height: 6vw;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 4.6vw;
  letter-spacing: 0.08vw;
  color: black;
  font-family: 'Dixon';
  text-transform: uppercase;
  line-height: 5vw;
  margin: 0;
  padding: 0;
  width: 20vw;
}

h3 {
  width: 60vw;
  font-size: 1.5vw;
  color: black;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
}

h4 {
  width: 83vw;
  height: 3vw;
  font-size: 1.1vw;
  color: black;
  margin: 0;
  padding: 1.8vw;
  font-family: 'Roboto Mono', monospace;
}

.logo {
  position: absolute;
  left: 7.5vw;
  top: 7.5vw;
}

.manufacture {
  position: absolute;
  left: 7.5vw;
  top: 12.5vw;
  color: #515151;
}

div.login {
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 500;
  position: absolute;
  right: 7.5vw;
  top: calc(42.5vw);
  width: calc(15vw - 10px);
  height: calc(5vw - 10px);
  z-index: 4;
  text-transform: lowercase;
  cursor: pointer;
  padding: 5px;
  font-size: 3.5vw;
  letter-spacing: 0.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(74, 81, 157);
  color: white;
  box-shadow: 0px 0px 18px #adadad;
}

div.desc {
  position: absolute;
  left: 7.5vw;
  top: 52.5vw;
  width: 45vw;
  height: 10vw;
  line-height: 4.7vw;
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 500;
  font-size: 3.5vw;
  background-color: white;
  padding: 1px;
}

svg.flow {
  top: 125vw;
  left: 0;
  z-index: 2;
}

svg.flow > text {
  z-index: 4;
  font-size: 5vw;
  fill: rgb(92, 92, 92);
  font-family: 'Tungsten A', 'Tungsten B';
  text-transform: uppercase;
  font-weight: 600;
}

div.flow-desc {
  position: absolute;
  text-align: center;
  padding: 0vw 15vw;
  left: 7.5vw;
  top: 140vw;
  width: 55vw;
  line-height: 3vw;
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 2.3vw;
  background-color: white;
  color: rgb(92, 92, 92);
  margin-bottom: 400px;
}

svg.flow > circle {
  fill: rgb(142, 184, 232);
}

img.cycle {
  width: 44vw;
  top: 70vw;
  left: 28vw;
  position: absolute;
}
